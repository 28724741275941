import React from "react"
import { graphql } from 'gatsby'
import HomePageLayout from '../components/layouts/layout-homepage.js'
import DocsLinks from '../components/docsLinks.js'
import DocsResources from '../components/docsResources.js'

export const query = graphql`
query PageCommerceQuery {
  allDocsInfoYaml(filter: {productId: {eq: "commerce"}}) {
    nodes {
      id
      productId
      productName {
        currentName
        formerName
      }
      productIntro
      docs {
        description
        title
        links {
          href
          label
        }
      }
      resources {
        title
        imageFile
        description
        link {
          href
          label
        }
      }
    }
  }
  allSearchConfigYaml(filter: {productId: {eq: "commerce"}}) {
    nodes {
      productId
      algolia {
        apiKey
        appId
        indexName
        placeholderText
      }
    }
  }
}
`

const PageCommerce = ({data}) => {
  const queryData = data.allDocsInfoYaml.nodes[0]
  const productName = queryData.productName
  const productIntro = queryData.productIntro
  const pageInfo = {
    title: "Elastic Path Self Managed Commerce Documentation - Developer Guides and Cloud Deployment Guides",
    description: "Search or browse documentation for Self Managed Commerce. Learn how to build your commerce solution, consume Self Managed Commerce APIs in your shopper experiences, connect to your data, and deploy your commerce solution.",
    url: "https://documentation.elasticpath.com/commerce-docs/"
  }

  return (
    <HomePageLayout
      pageInfo={pageInfo}
      searchConfig={data.allSearchConfigYaml.nodes[0]}
      productName={productName}
      productIntro={productIntro}>

      <section className="docs">
        <DocsLinks data={queryData.docs} />
      </section>
      <section className="resources">
        <div className="ep-card">
          <h2 className="ep-card-title">Explore more {productName.currentName} resources</h2>
          <DocsResources data={queryData.resources} />
        </div>
      </section>

    </HomePageLayout>
  )
}

export default PageCommerce
