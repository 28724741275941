import React from "react";
import GlobalLayout from "./layout-global.js";
import layoutStyle from "./layout-homepage.module.css";
import Search from "../search/App";

export default ({ children, pageInfo, searchConfig, productName, productIntro, theme }) => {
  let containerStyle;

  if (theme === 'OrangeOnDeepBlue') {
    containerStyle = layoutStyle.OrangeOnDeepBlue
  } else if (theme === 'BlueberryOnDeepBlue') {
    containerStyle = layoutStyle.BlueberryOnDeepBlue
  }

  return (
    <GlobalLayout pageInfo={pageInfo} >
      <div className={layoutStyle.wrapper}>
          <section className={layoutStyle.leftcolumn}>
            <h2 className={layoutStyle.leftheading}>{productName.currentName}
              {
                productName.formerName ?
                  <span className={layoutStyle.productNameFormer}>(formerly {productName.formerName})</span> :
                  null
              }
            </h2>
            <p className={layoutStyle.leftparagraph}>{productIntro}</p>
          </section>
          <section className={layoutStyle.rightcolumn}>
          </section>
      </div>
      <div className={layoutStyle.container}>
        <div className={`${containerStyle} ${layoutStyle.contents}`}>
          {
            searchConfig ?
              <section className={layoutStyle.search}>
                <Search searchConfig={searchConfig} />
              </section> :
              null
          }

          {children}
        </div>
      </div>
    </GlobalLayout>
  )
}
