import React from "react"
import DocsResourcesStyle from "./docsResources.module.css"
import { withPrefix } from "gatsby"

const DocsResourcesContainer = ({ data }) => (
  <div className={DocsResourcesStyle.resourcesContainer}>
    {data.map((resources, index) => (
      <div className={DocsResourcesStyle.resource} key={index}>
        <DocsResourcesContents data={resources} />
      </div>
    ))}
  </div>
)

const DocsResourcesContents = ({ data }) => (
  <React.Fragment>
    <h3 className={DocsResourcesStyle.resourceTitle}>{data.title}</h3>
    <img
      alt="Available resource"
      src={withPrefix("/" + data.imageFile)}
      className={DocsResourcesStyle.resourceImage}
    />
    <p className={DocsResourcesStyle.resourceDescription}>{data.description}</p>
    <a href={data.link.href}>{data.link.label}</a>
  </React.Fragment>
)

export default DocsResourcesContainer
