import React from "react"
import Helmet from "react-helmet"
import DocsLinksStyle from './docsLinks.module.css'

const DocsLinksContainer = ({data}) => (
  <div className={DocsLinksStyle.container}>
    {
      data.map((docs, index) => (
        <div className="ep-card" key={index}>
            <DocsLinksContents data={docs} />
        </div>
      ))
    }
  </div>
)

const DocsLinksContents = ({data}) => (
  <React.Fragment>
    <h3 className="ep-card-title">{data.title}</h3>
    <p>{data.description}</p>
      <ul >
        {
          data.links.map((link, index) =>(
            <li key={index} className={DocsLinksStyle.listItems}>
              <a href={link.href}>{link.label}</a>
            </li>
          ))
        }
      </ul>

      {
        data.others ?
          <React.Fragment>
            <Helmet>
              <script type="module" src="https://documentation.elasticpath.com/_lib/ep-webcomponent/public/EpButtonCTA.js" />
            </Helmet>
            <ep-button-cta label={data.others.label} href={data.others.href}></ep-button-cta>
          </React.Fragment>
          : null
      }
  </ React.Fragment>
)

export default DocsLinksContainer
